
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[portalName]",
      function () {
        return require("private-next-pages/[portalName]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[portalName]"])
      });
    }
  