
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { usePortal } from "contexts/PortalContext";
import { useTeam } from "contexts/TeamContext";
import { useUser } from "contexts/UserContext";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { HOME_ROUT, DEFAULT_PORTAL_NAME } from "utils/constants";
import withProtectedRoute from "HOCs/withProtectedRoute";
import { GetServerSideProps } from "next";
import { MetaDataProps, getServerSideMetaData } from "utils/getServerSideProps";
import HomeView from "components/organisms/Home/HomeView";

 const _getServerSideProps: GetServerSideProps<MetaDataProps> =
  getServerSideMetaData;

const Portal: React.FC = () => {
  const router = useRouter();
  const { user, isFetched: userFetched } = useUser();
  const { push, currentTeam } = useTeam();
  const { urlPortalFetched, urlPortal, portalName, isPortal, isDefaultName } =
    usePortal();

  useEffect(() => {
    if (userFetched && !user) {
      router.push(`/${portalName}/auth/login`);
    } else if (currentTeam && user) {
      if (isPortal && !urlPortal && urlPortalFetched) {
        router.push(`/${DEFAULT_PORTAL_NAME}`);
      } else if (isDefaultName) {
        push(HOME_ROUT);
      }
    }
  }, [
    user,
    currentTeam,
    userFetched,
    urlPortalFetched,
    urlPortal,
    isPortal,
    isDefaultName,
  ]);

  if (isDefaultName || !user) {
    return null;
  }

  return <HomeView />;
};

export default withProtectedRoute(Portal);


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[portalName]/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  